import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import
{
  ACTIVE_STATE,
  CANCELED_STATE,
  DATA_API_BASE_URL,
  DONE_STATE,
  ERROR_STATE,
  FAILED_STATE,
  FETCHING_STATE,
  INACTIVE_STATE,
  INVALID_TOKEN_STATE,
  UNAVAILABLE_STATE,
} from "./constants";
import { InactiveScreen } from "./inactive-screen";
import { LoadingScreen } from "./loading-screen";
import { DoneScreen } from "./done-screen";
import { CanceledScreen } from "./canceled-screen";
import { UnavailableScreen } from "./unavailable-screen";
import { TestControl } from "./test-control";

export const Activation = ({ context }) =>
{
  const [data, setData] = useState(true);
  const [isProcess, setProcess] = useState(false);
  const [isDone, setDone] = useState(false);
  const [isCanceled, setCanceled] = useState(false);
  const [testState, setTestState] = useState(false);
  const resultPriceListId = data && data.activationInfo && data.activationInfo.id || null;
  const isFetching = data === true || isProcess;
  const isError = data === false;
  const state = (data && data.state) || null;
  let status =
    testState ||
    (isFetching && FETCHING_STATE) ||
    (isError && ERROR_STATE) ||
    (isDone && DONE_STATE) ||
    (isCanceled && CANCELED_STATE) ||
    state ||
    FAILED_STATE;

  // go to canceled if pricelist was canceled before
  if (status == INACTIVE_STATE && data.lastChangeDateTime)
  {
    status = CANCELED_STATE;
  }

  const priceListId = context.priceListId || null;
  const inactiveHeadline = context.inactiveHeadline || "";
  const inactiveParagraph = context.inactiveParagraph || "";
  const activeHeadline = context.activeHeadline || "";
  const activeParagraph = context.activeParagraph || "";
  const activeParagraph2 = context.activeParagraph2 || "";
  const unavailableHeadline = context.unavailableHeadline || "";
  const unavailableParagraph = context.unavailableParagraph || "";
  const canceledHeadline = context.canceledHeadline || "";
  const canceledParagraph = context.canceledParagraph || "";
  const errorHeadline = context.errorHeadline || "";
  const errorParagraph = context.errorParagraph || "";
  const title = context.title || "";
  const hash = context.hash || "";
  const debug = context.debug;

  if (!isFetching && (!priceListId || !resultPriceListId || priceListId !== resultPriceListId))
  {
    status = INVALID_TOKEN_STATE;
  }

  useEffect(() =>
  {
    loadPricelist();
  }, []);

  const loadPricelist = async () =>
  {
    setData(true);

    const formData = new FormData();
    formData.append("token", hash);

    try
    {
      const data = await axios.post(`${DATA_API_BASE_URL}pricelist/data`, formData);
      console.log("test:::", data.data.result, priceListId);

      if (data.data.success)
      {
        setData(data.data.result);
      } else
      {
        setData(false);
      }
    } catch (e)
    {
      setData(false);
    }
  };

  const activatePricelist = async () =>
  {
    setProcess(true);

    const formData = new FormData();
    formData.append("token", hash);
    formData.append("action", "activate");

    try
    {
      const data = await axios.post(`${DATA_API_BASE_URL}pricelist/activation`, formData);

      if (data.data.success)
      {
        setData(data.data.result);
        setDone(true);
        setCanceled(false);
      } else
      {
        setData(false);
      }
      setProcess(false);
    } catch (e)
    {
      setProcess(false);
    }
  };

  const deactivatePricelist = async () =>
  {
    setProcess(true);

    const formData = new FormData();
    formData.append("token", hash);
    formData.append("action", "revoke_activation");

    try
    {
      const data = await axios.post(`${DATA_API_BASE_URL}pricelist/activation`, formData);

      if (data.data.success)
      {
        setData(data.data.result);
        setDone(false);
        setCanceled(true);
      } else
      {
        setData(false);
      }
      setProcess(false);
    } catch (e)
    {
      setProcess(false);
    }
  };

  let content;

  switch (status)
  {
    case FETCHING_STATE:
      content = <LoadingScreen breadcrumbsText={title} />;
      break;
    case FAILED_STATE:
      content = <UnavailableScreen headline={errorHeadline} paragraph={errorParagraph} />;
      break;
    case INACTIVE_STATE:
      content = <InactiveScreen data={data || {}} action={activatePricelist} headline={inactiveHeadline} paragraph={inactiveParagraph} breadcrumbsText={title} />;
      break;
    case ACTIVE_STATE:
    case DONE_STATE:
      content = <DoneScreen data={data || {}} action={deactivatePricelist} headline={activeHeadline} paragraph={activeParagraph} paragraph2={activeParagraph2} breadcrumbsText={title} />;
      break;
    case INVALID_TOKEN_STATE:
      content = <UnavailableScreen headline={errorHeadline} paragraph={errorParagraph} />;
      break;
    case UNAVAILABLE_STATE:
      content = <UnavailableScreen headline={unavailableHeadline} paragraph={unavailableParagraph} />;
      break;
    case CANCELED_STATE:
      content = <CanceledScreen data={data || {}} headline={canceledHeadline} paragraph={canceledParagraph} breadcrumbsText={title} />;
      break;
    case ERROR_STATE:
    default:
      content = <UnavailableScreen headline={errorHeadline} paragraph={errorParagraph} />;
  }

  return (
    <Fragment>
      {content}
      {debug && <TestControl setState={setTestState} />}
    </Fragment>
  );
};
